<template>
  <base-layout-two :page-title="title" :page-default-back-link="`/users/${$route.params.id}/folios`"
    content-class="background-white">
    <section class="padding-x-16 padding-bottom-16">
      <h1>{{ title }}</h1>
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="12" size-lg="6">
            <ion-button fill="outline" expand="block" @click="showPurchaseCreateModal = true">
              Add Purchase
            </ion-button>
          </ion-col>

          <ion-col size="12" size-lg="6">
            <ion-button fill="outline" expand="block" @click="showLineItemCreateModal = true">
              Add Custom Line Item
            </ion-button>
          </ion-col>

          <ion-col size="12">
            <ion-button expand="block"
              :router-link="`/users/${$route.params.id}/folios/${$route.params.folio_id}/payments/create`"
              :disabled="balance.amount >= 0 ? true : false">
              Request Payment
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <ion-list class="padding-bottom-24">
      <!-- Line Items -->
      <ion-item-group>
        <ion-item-divider>
          <ion-label> Line Items </ion-label>
        </ion-item-divider>

        <transaction-list-item v-for="transaction in line_items" :key="transaction.id" :transaction="transaction"
          :router-link="`/users/${$route.params.id}/folios/${$route.params.folio_id}/transactions/${transaction.id}`"></transaction-list-item>
      </ion-item-group>

      <!-- Totals -->
      <ion-item-group>
        <ion-item-divider>
          <ion-label> Totals </ion-label>
        </ion-item-divider>

        <transaction-list-item :transaction="subtotal"> </transaction-list-item>

        <transaction-list-item :transaction="taxesAndFees">
        </transaction-list-item>

        <transaction-list-item :transaction="total"> </transaction-list-item>
      </ion-item-group>

      <!-- Payments -->
      <ion-item-group class="margin-top-48">
        <ion-item-divider>
          <ion-label> Payments </ion-label>
        </ion-item-divider>

        <transaction-list-item v-for="transaction in payments" :key="transaction.id" :transaction="transaction"
          :router-link="`/users/${$route.params.id}/folios/${$route.params.folio_id}/transactions/${transaction.id}`"></transaction-list-item>
      </ion-item-group>

      <!-- Balance -->
      <ion-item-group>
        <ion-item-divider>
          <ion-label> Balance </ion-label>
        </ion-item-divider>

        <transaction-list-item :transaction="balance"> </transaction-list-item>
      </ion-item-group>
    </ion-list>

    <!-- Purchase Create Modal -->
    <ion-modal :is-open="showPurchaseCreateModal" @didDismiss="showPurchaseCreateModal = false">
      <purchase-create-modal @add-purchase="addTransaction" @cancel-modal="() => (this.showPurchaseCreateModal = false)">
      </purchase-create-modal>
    </ion-modal>

    <!-- Line Item Create Modal -->
    <ion-modal :is-open="showLineItemCreateModal" @didDismiss="showLineItemCreateModal = false">
      <line-item-create-modal @add-line-item="addTransaction"
        @cancel-modal="() => (this.showLineItemCreateModal = false)">
      </line-item-create-modal>
    </ion-modal>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItemDivider,
  IonItemGroup,
  IonButton,
  IonLabel,
  IonModal,
} from "@ionic/vue";
import LineItemCreateModal from "@/components/users/folios/LineItemCreateModal.vue";
import PurchaseCreateModal from "@/components/users/folios/PurchaseCreateModal.vue";
import TransactionListItem from "@/components/users/folios/TransactionListItem.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonButton,
    IonModal,
    LineItemCreateModal,
    PurchaseCreateModal,
    TransactionListItem,
  },

  data() {
    return {
      folio: {
        transactions: [],
      },
      showPurchaseCreateModal: false,
      showLineItemCreateModal: false,
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    title() {
      if (this.folio.reservation) {
        return `Reservation ${this.folio.confirmation}`;
      } else return `Order ${this.folio.confirmation}`;
    },

    line_items() {
      let line_items = this.folio.transactions.filter(
        (x) => x.type === "line_item"
      );

      return line_items;
    },

    payments() {
      let payments = this.folio.transactions.filter((x) => {
        if (
          x.type === "charge" &&
          x.stripe_payment_intent_status === "succeeded"
        )
          return true;
        return false;
      });

      return payments;
    },

    subtotal() {
      let subtotal = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_final,
        0
      );

      let transaction = {
        description: "Subtotal",
        amount: Number(subtotal),
      };

      return transaction;
    },

    taxesAndFees() {
      let taxesAndFees = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_taxes,
        0
      );

      let transaction = {
        description: "Taxes & Fees",
        amount: Number(taxesAndFees),
      };

      return transaction;
    },

    total() {
      let total = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_total,
        0
      );

      let transaction = {
        description: "Total",
        amount: Number(total),
      };

      return transaction;
    },

    balance() {
      let paymentsTotal = this.payments.reduce(
        (partial_sum, a) => partial_sum + (a.stripe_charge_amount / 100 - a.stripe_refund_amount / 100),
        0
      );

      let transaction = {
        description: "Balance",
        amount: paymentsTotal + this.total.amount,
      };

      return transaction;
    },
  },

  ionViewWillEnter() {
    this.fetchFolio();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async addTransaction(transaction) {
      this.showPurchaseCreateModal = false;
      this.showLineItemCreateModal = false;

      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/transactions`,
        data: transaction,
      };

      config.data.folio_id = this.folio.id;
      config.data.created_by_id = this.authUser.id;

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.transaction = response.data.transaction;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Line Item Created", color: "secondary" });

          this.fetchFolio();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },

    async fetchFolio() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/folios/${this.$route.params.folio_id}`)
        .then((response) => {
          this.folio = response.data.folio;

          if (this.folio.transactions) {
            this.folio.transactions = this.folio.transactions.sort((a, b) => {
              return new Date(a.date) - new Date(b.date);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>